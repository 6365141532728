:root {
  --color-block: #EEEEEE;
  --color-solid: #666;
  --color-primary: #4783fb;
  --color-accent: #84fab0;
}

.App {
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
}

.outside-container {
  background-color: var(--color-block);
  border-radius: 0.5rem;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
  width: 80%;
  color: var(--color-solid);

  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
}

h2 {
  color: var(--color-solid);
  font-size: 18px;
  text-align: left;
  width: 90%;
  margin: 5px 0;
}

.footer {
  font-size: 12px;
  margin-top: 1rem;
  color: black;
  display: block;
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}
.fa-coffee {
  color: #be5915;
  margin: 0 5px;
}

.fa-github {
  color: #000;
  margin: 0 5px;
}

